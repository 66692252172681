import { AxiosError } from 'axios';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { UseMutationOptions, UseQueryOptions } from 'react-query/types/react/types';
import { TemplateError, useApi } from '@tymely/api';
import { ITemplateVariable, IResponseTemplate } from '@tymely/atoms';

import { useSetAlert } from './alerts.services';

export const useFetchResponseQuery = (
    templateId?: number,
    options?: UseQueryOptions<IResponseTemplate | null, TemplateError>,
) => {
    const api = useApi();
    const setAlert = useSetAlert();
    return useQuery<IResponseTemplate | null, TemplateError>(
        ['response-template', templateId],
        () => (templateId ? api.get(`response-template/${templateId}`) : Promise.resolve(null)),
        {
            onError: () => {
                setAlert(`Failed to load template ${templateId}`, 'error');
            },
            enabled: Boolean(templateId),
            ...options,
        },
    );
};

export const useRenderTemplateQuery = (
    template: string,
    variables: Record<string, ITemplateVariable>,
    sanitize?: boolean,
    options?: UseQueryOptions<string>,
) => {
    const api = useApi();
    return useQuery<string>(
        ['rendered-template', template, variables, sanitize],
        () => api.post('template/render', { template, variables, sanitize }),
        {
            ...options,
            retry: false,
        },
    );
};

export const useTemplateFreeVariablesQuery = (template: string, options?: UseQueryOptions<string[]>) => {
    const api = useApi();
    return useQuery<string[]>(['free-vars', template], () => api.post('template/free-variables', { template }), {
        ...options,
        retry: false,
    });
};

export const EDIT_TEMPLATE_MUTATION_KEY = 'edit-response-template';

export const useEditResponseTemplateMutation = (
    options?: UseMutationOptions<IResponseTemplate, AxiosError | TemplateError, IResponseTemplate>,
) => {
    const api = useApi();
    const queryClient = useQueryClient();
    return useMutation<IResponseTemplate, AxiosError | TemplateError, IResponseTemplate>(
        (template) => api.put(`response-template/${template.id}`, template),
        {
            mutationKey: EDIT_TEMPLATE_MUTATION_KEY,
            onSuccess: (data) => {
                queryClient.setQueriesData(['response-template', data.id], () => data);
            },
            ...options,
        },
    );
};

export const useCreateResponseTemplateMutation = () => {
    const api = useApi();
    const queryClient = useQueryClient();
    return useMutation<IResponseTemplate, AxiosError, Omit<IResponseTemplate, 'id'>>(
        (template) => api.post('response-template', template),
        {
            onSuccess: (data) => {
                queryClient.setQueriesData(['response-template', data.id], () => data);
            },
        },
    );
};
