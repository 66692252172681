import { useQuery, useMutation, useQueryClient } from 'react-query';
import { JSONSchema7 } from 'json-schema';
import { IActionMetadata, IArgumentMetadata } from '@tymely/atoms';
import { useApi } from '@tymely/api';
import { AxiosError } from 'axios';

import { useSetAlert } from './alerts.services';

const ACTIONS_METADATA_QUERY_KEY = 'actionsMetadata';
const EXECUTOR_SCHEMA_QUERY_KEY = 'executorSchema';

export const useActionsMetadataQuery = (onSuccess?: (actionsMetadata: IActionMetadata[]) => void) => {
    const api = useApi();
    const setAlert = useSetAlert();

    return useQuery<IActionMetadata[]>(
        ACTIONS_METADATA_QUERY_KEY,
        () => api.get('action-metadatas/list', { params: { limit: 1000 } }),
        {
            onSuccess,
            onError: () => {
                setAlert('failed fetching actions metadata', 'error');
            },
        },
    );
};

export const useActionMetadataExecutorSchema = (
    metadataId: IActionMetadata['id'],
    onSuccess?: (executorSchema: JSONSchema7) => void,
) => {
    const api = useApi();
    const setAlert = useSetAlert();
    return useQuery<JSONSchema7>(
        [ACTIONS_METADATA_QUERY_KEY, metadataId, EXECUTOR_SCHEMA_QUERY_KEY],
        () => api.get(`action-metadata/${metadataId}/executor-schema`),
        {
            onSuccess,
            onError: () => {
                setAlert('failed fetching action metadata executor schema', 'error');
            },
        },
    );
};

export const useEditActionMetadataMutation = () => {
    const api = useApi();
    const queryClient = useQueryClient();
    return useMutation<IActionMetadata, AxiosError, IActionMetadata>(
        (metadata) => api.put(`action-metadata/${metadata.id}`, metadata),
        {
            onSuccess: (data) => {
                queryClient.setQueriesData<IActionMetadata[] | undefined>(
                    ACTIONS_METADATA_QUERY_KEY,
                    (cache?: IActionMetadata[]) => cache?.map((item) => (item.id === data.id ? data : item)),
                );
            },
        },
    );
};

export const useCreateActionMetadataMutation = () => {
    const api = useApi();
    const queryClient = useQueryClient();
    return useMutation<IActionMetadata, AxiosError, { metadata: Omit<IActionMetadata, 'id'> }>(
        (params) => api.post('action-metadata', params.metadata),
        {
            onSuccess: (data) => {
                queryClient.setQueriesData(ACTIONS_METADATA_QUERY_KEY, (cache?: IActionMetadata[]) =>
                    cache ? cache.concat(data) : [data],
                );
            },
        },
    );
};

export const useArgsInActionsQuery = (
    onSuccess?: (
        usage: Promise<Record<IActionMetadata['id'], IArgumentMetadata['name'][]>> extends Promise<infer T> ? T : never,
    ) => void,
    onError?: (error: AxiosError) => void,
) => {
    const api = useApi();
    return useQuery<Record<IActionMetadata['id'], IArgumentMetadata['name'][]>, AxiosError>(
        'args-in-actions',
        () => api.get('args-in-actions'),
        {
            onSuccess,
            onError,
        },
    );
};
