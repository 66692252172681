import { AxiosError } from 'axios';
import { JSONSchema7 } from 'json-schema';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { ILlmExperiment, ILlmExperimentCreate, LlmExperimentDataSetType } from '@tymely/atoms';
import { useApi } from '@tymely/api';

import { useSetAlert } from './alerts.services';

export const LLM_EXPERIMENTS_SEARCH_QUERY_KEY = 'llm-experiments-history';

export const useLlmExperimentsSearch = (
    experimentType: ILlmExperiment['experiment_type'],
    optimizationTargetName: string,
    enabled = true,
) => {
    const api = useApi();
    return useQuery<ILlmExperiment[]>(
        [LLM_EXPERIMENTS_SEARCH_QUERY_KEY, experimentType, optimizationTargetName],
        () =>
            api.get('llm-experiments/search', {
                params: {
                    experiment_type: experimentType,
                    optimization_target_name: optimizationTargetName,
                },
            }),
        { enabled },
    );
};

export const useCreateLlmExperiment = () => {
    const api = useApi();
    const setAlert = useSetAlert();
    const queryClient = useQueryClient();
    return useMutation<ILlmExperiment, AxiosError, ILlmExperimentCreate>(
        (llmExperiment) => api.post('llm-experiment', llmExperiment),
        {
            onSuccess: (llmExperiment) =>
                queryClient.invalidateQueries([
                    LLM_EXPERIMENTS_SEARCH_QUERY_KEY,
                    llmExperiment.experiment_type,
                    llmExperiment.additional_data.optimization_target_name,
                ]),
            onError: (error) => {
                setAlert(error.message, 'error', 5000, 'Failed creating LLM experiment.');
            },
        },
    );
};

export const useRunLlmExperiment = () => {
    const api = useApi();
    return useMutation(
        ({
            experimentId,
            dataset,
        }: {
            experimentId: ILlmExperiment['id'];
            dataset:
                | {
                      type: LlmExperimentDataSetType;
                      ids: number[];
                      name: string;
                  }
                | undefined;
        }) => api.put(`llm-experiment/${experimentId}/run-offline`, { dataset }),
    );
};

export const useLlmExperimentSchemaQuery = () => {
    const api = useApi();
    return useQuery('organization-schema', () => api.get('llm-experiment/schema') as Promise<JSONSchema7>);
};
