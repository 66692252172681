import React from 'react';
import { Box, Grid, styled, Typography } from '@mui/material';
import {
    AppMode,
    useAppMode,
    useCreateHandlingDurationsCrumb,
    useHasPolicy,
    useSelectedComment,
    useTicket,
} from '@tymely/services';
import { IDineshTicketOperations } from '@tymely/atoms';
import { ReactFlowProvider } from 'reactflow';
import ArgLineageModal from '@tymely/features/ArgLineageModal';

import Decision from './Decision';
import ArgumentActions from './ArgumentsActions';
import IntentActions from './IntentActions';
import ArgumentTabs, { ArgumentsTabsRef } from './ArgumentsTabs';

const _UnsupportedIntent = styled(Box)`
    height: 100%;
    align-items: center;
    justify-content: center;
    display: flex;
`;

const _Grid = styled(Grid)`
    text-align: center;
`;

export const UnsupportedIntent = () => (
    <_UnsupportedIntent>
        <_Grid item xs={12}>
            <Typography variant="h6" color="text.disabled">
                This intent is not supported yet
            </Typography>
        </_Grid>
    </_UnsupportedIntent>
);

const _Root = styled(Box)`
    display: flex;
    flex-direction: column;
    height: 100%;
`;

type ArgumentSectionProps = {
    argsVersion?: string;
    argsLastUpdate?: Date;
    setHighlightText?: (text: string) => void;
};

export const useIntentData = () => {
    const { appMode } = useAppMode();
    const ticket = useTicket();
    const comment = useSelectedComment();
    const createHandlingDurationsCrumb = useCreateHandlingDurationsCrumb();

    const hasPolicyQuery = useHasPolicy(ticket.organization.org_policy_set_id, comment?.selected_intent_id || null, {
        onSuccess: (data) => {
            if (!data) {
                createHandlingDurationsCrumb(IDineshTicketOperations.USER_TAGGED_UNSUPPORTED_INTENT);
            }
        },
    });

    const isIntentSupported = !!hasPolicyQuery.data || hasPolicyQuery.isLoading;
    const isIntentApproved =
        appMode !== AppMode.Online || !!comment?.agent_username || !!comment?.additional_data.is_intent_approved;

    return { isIntentSupported, isIntentApproved };
};

const ArgumentSections = ({ argsLastUpdate, argsVersion, setHighlightText }: ArgumentSectionProps) => {
    const { isIntentApproved, isIntentSupported } = useIntentData();
    const [openArgDepModal, setOpenArgDepModal] = React.useState(false);
    const tabsRef = React.useRef<ArgumentsTabsRef>(null);

    if (!isIntentSupported) {
        return <UnsupportedIntent />;
    }

    return (
        <_Root>
            <Decision version={argsVersion} />
            <IntentActions version={argsVersion} />
            {isIntentApproved && (
                <>
                    <ArgumentActions onDependencyGraph={() => setOpenArgDepModal(true)} />
                    <ArgumentTabs
                        ref={tabsRef}
                        version={argsVersion}
                        argsLastUpdate={argsLastUpdate}
                        onHighlightText={setHighlightText}
                    />
                </>
            )}
            {openArgDepModal && (
                <ReactFlowProvider>
                    <ArgLineageModal open onClose={() => setOpenArgDepModal(false)} />
                </ReactFlowProvider>
            )}
        </_Root>
    );
};

export default ArgumentSections;
