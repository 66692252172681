import flatten from 'lodash/flatten';
import React from 'react';
import {
    useAppMode,
    useConversation,
    useEventSubscription,
    useSelectedComment,
    useSetTicket,
    useTicket,
    useTicketEventsQuery,
    useUpdateCommentsQuery,
} from '@tymely/services';
import { pdfjs } from 'react-pdf';
import { useApi } from '@tymely/api';
import { ITicket } from '@tymely/atoms';

import { Response } from '../Response';
import { BottomScrollButton, UpScrollButton } from './ScrollButton';
import Discussion from './Discussion';
import { ConversationContainer } from './styled';

import 'react-pdf/dist/Page/TextLayer.css';
import 'react-pdf/dist/Page/AnnotationLayer.css';

pdfjs.GlobalWorkerOptions.workerSrc = new URL('pdfjs-dist/build/pdf.worker.min.mjs', import.meta.url).toString();

type ConversationProps = {
    highlights?: [number, number][];
    highlightText?: string;
    canSave?: boolean;
    canReview?: boolean;
    canSubmit?: boolean;
    eventsMode?: boolean;
};

const Conversation = React.memo(({ highlightText, eventsMode, ...rest }: ConversationProps) => {
    const chatRef = React.useRef<{
        scrollToTop: () => void;
        scrollToBottom: () => void;
    }>(null);

    const { isOnline } = useAppMode();
    const ticket = useTicket();
    const {
        data: ticketEventsData,
        isLoading: isLoadingEvents,
        isFetchingNextPage: isFetchingNextPageEvents,
        fetchNextPage,
        hasNextPage: hasNextPageEvents,
    } = useTicketEventsQuery(ticket.id, eventsMode);

    React.useEffect(() => {
        if (hasNextPageEvents) {
            fetchNextPage();
        }
    }, [ticketEventsData, hasNextPageEvents]);

    const ticketEvents = React.useMemo(() => flatten(ticketEventsData?.pages), [ticketEventsData]);
    const [isScrolledToTop, setIsScrolledToTop] = React.useState(true);
    const [isScrolledToBottom, setIsScrolledToBottom] = React.useState(true);
    const { refetch: refetchComments } = useUpdateCommentsQuery({
        onSuccess() {
            if (isOnline) {
                setCommentIdToFocusOn(undefined);
            }
            chatRef.current?.scrollToBottom();
        },
    });
    const api = useApi();
    const setTicket = useSetTicket();

    useEventSubscription({ channel: `ticket/${ticket.id}`, objectTypes: ['Comment', 'Ticket'] }, async (event) => {
        if (event.object_type === 'Ticket') {
            const ticketUpdated = (await api.get(`ticket/${ticket.id}`)) as ITicket;
            setTicket(ticketUpdated);
        } else if (event.object_type === 'Comment' && event.action === 'created') {
            await refetchComments();
        }
    });

    const { comments, loaded, setCommentIdToFocusOn } = useConversation();
    const selectedComment = useSelectedComment();

    return (
        <ConversationContainer id="conversation">
            <UpScrollButton disabled={isScrolledToTop} onClick={chatRef.current?.scrollToTop} />
            <Discussion
                highlightText={highlightText}
                ref={chatRef}
                ticket={ticket}
                comments={comments}
                commentIdToFocusOn={selectedComment?.id}
                selectedCommentId={selectedComment?.id}
                loading={!loaded}
                onSelectComment={!isOnline ? setCommentIdToFocusOn : undefined}
                onDeselectComment={() => setCommentIdToFocusOn(undefined)}
                events={{
                    isLoading: isLoadingEvents || isFetchingNextPageEvents,
                    ticketEvents: eventsMode ? ticketEvents : undefined,
                }}
                onScrollToTop={setIsScrolledToTop}
                onScrollToBottom={setIsScrolledToBottom}
            />
            <BottomScrollButton disabled={isScrolledToBottom} onClick={chatRef.current?.scrollToBottom} />
            <Response
                {...rest}
                onResize={() => {
                    if (isScrolledToBottom) {
                        setTimeout(() => chatRef.current?.scrollToBottom(), 200); // wait for resize animation & relayout
                    }
                }}
            />
        </ConversationContainer>
    );
});

Conversation.displayName = 'Conversation';

export default Conversation;
