import React from 'react';
import { IDineshTicketOperations, isPolicyEvaluationRunning } from '@tymely/atoms';
import { StaleTicketUpdate } from '@tymely/api';
import { useSetRecoilState } from 'recoil';

import { AppMode, useAppMode } from './mode';
import { useCreateTicketCrumb } from './ticketTrail.services';
import { useOnlineTicketQuery } from './ticket.services';
import { useSelectedCommentDecisionQuery, useSelectedComment } from './comment.services';

export const useTicketNav = () => {
    const { appMode, isOnline, isChat, switchMode, shiftEnd, navigateTicket } = useAppMode();
    const selectedComment = useSelectedComment();
    const onlineTicketQuery = useOnlineTicketQuery({ enabled: false });
    const createTicketCrumb = useCreateTicketCrumb();
    const { data: decision } = useSelectedCommentDecisionQuery(false);
    const setPolicyEvaluationRunning = useSetRecoilState(isPolicyEvaluationRunning);

    const handleNext = React.useCallback(
        async (waitFor?: Promise<unknown>) => {
            if (decision?.status === 'BETA') {
                await waitFor;
            } else {
                waitFor?.catch(async (e) => {
                    if (e instanceof StaleTicketUpdate) {
                        return;
                    }
                });
            }

            if (isChat) {
                await Promise.resolve(waitFor).then(() => {
                    window.open('', '_self', '');
                    window.close();
                });
            } else if (shiftEnd) {
                switchMode(AppMode.Training);
            } else if (isOnline) {
                createTicketCrumb(IDineshTicketOperations.USER_REDIRECTED_TO_NEXT_TICKET);
                onlineTicketQuery.refetch();
                setPolicyEvaluationRunning(false);
            } else {
                navigateTicket(null, appMode, true /* replace */);
                setPolicyEvaluationRunning(false);
            }
        },
        [
            selectedComment,
            navigateTicket,
            isOnline,
            isChat,
            shiftEnd,
            switchMode,
            appMode,
            createTicketCrumb,
            onlineTicketQuery,
        ],
    );

    return {
        handleNext,
    };
};
