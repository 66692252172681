import { ChangeEvent, FocusEvent, forwardRef, lazy, memo, Suspense, useCallback, useEffect, useState } from 'react';
import { Box, InputBaseComponentProps, useTheme } from '@mui/material';
import { IStringValueArgument } from '@tymely/atoms';
import { useQuery } from 'react-query';
import { useApi } from '@tymely/api';
import { GOOGLE_MAPS_API_KEY_ENTRY } from '@tymely/config';

import { ArgumentFieldProps } from './Layout';
import { InputFieldArgument } from './InputFieldArgument';

import('react-google-autocomplete');

const Autocomplete = lazy(() => import('react-google-autocomplete'));

const GoogleAddressAutocomplete = forwardRef<HTMLInputElement, InputBaseComponentProps>((props, ref) => {
    const api = useApi();
    const { onChange, onBlur } = props;
    const [result, setResult] = useState<string | null>(null);
    const { data: resourceSecrets } = useQuery(
        'google-maps-api-key',
        () => api.post('third-party/service-provider-secrets', { keys: [GOOGLE_MAPS_API_KEY_ENTRY] }) as Promise<any>,
    );
    const googleMapsApiKey = resourceSecrets?.[GOOGLE_MAPS_API_KEY_ENTRY];

    const onPlaceSelected = useCallback(
        (address: { formatted_address: string }) => {
            onChange?.({
                target: { value: address.formatted_address },
            } as ChangeEvent<HTMLInputElement>);
            setResult(address.formatted_address);
        },
        [onChange],
    );
    const theme = useTheme();

    useEffect(() => {
        if (result) {
            onBlur?.({} as FocusEvent<HTMLInputElement>);
            setResult(null);
        }
    }, [result]);

    return (
        <Box
            width="100%"
            sx={{
                pl: 2,
                pr: 2,
                input: {
                    margin: 0,
                    padding: 0,
                    border: 0,
                    width: '100%',
                    outline: 0,
                },
            }}
        >
            <Suspense fallback={'Loading google maps component...'}>
                {googleMapsApiKey && (
                    <Autocomplete
                        {...props}
                        ref={ref}
                        onBlur={undefined}
                        apiKey={googleMapsApiKey}
                        options={{
                            types: ['address'],
                            fields: ['formatted_address', 'geometry', 'name'],
                            strictBounds: false,
                        }}
                        onPlaceSelected={onPlaceSelected}
                        style={{ height: theme.spacing(5) }}
                    />
                )}
            </Suspense>
        </Box>
    );
});

GoogleAddressAutocomplete.displayName = 'GoogleAddressAutocomplete';

export const AddressArgument = memo((props: ArgumentFieldProps<IStringValueArgument>) => {
    return <InputFieldArgument {...props} inputComponent={GoogleAddressAutocomplete} />;
});

AddressArgument.displayName = 'AddressArgument';
