import { useApi } from '@tymely/api';
import { IPolicySet } from '@tymely/atoms';
import { INTENTS_CUSTOM_CATEGORY } from '@tymely/config';
import { useCallback } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import { useSetAlert } from './alerts.services';

const INTENTS_QUERY_KEY = 'intents';
const DECISION_LLM_CLUSTERS_QUERY_KEY = 'decisionLlmClusters';

export const useIntentsQuery = (onSuccess?: (intents?: IPolicySet[]) => void) => {
    const api = useApi();
    return useQuery<IPolicySet[]>(INTENTS_QUERY_KEY, () => api.get('policy-sets/list', { params: { limit: 1000 } }), {
        onSuccess: onSuccess,
        staleTime: Infinity,
    });
};

export const useCreateIntentMutation = (onSuccess?: (intent: IPolicySet) => void) => {
    const api = useApi();
    const setAlert = useSetAlert();
    const queryClient = useQueryClient();

    return useMutation(
        INTENTS_QUERY_KEY,
        (name: string) =>
            api.post('policy-set', { name, category: INTENTS_CUSTOM_CATEGORY, suggested: true }) as Promise<IPolicySet>,
        {
            onSuccess: async (data) => {
                await queryClient.invalidateQueries(INTENTS_QUERY_KEY);
                return onSuccess?.(data);
            },
            onError: (_, variables) => {
                setAlert(`Failed creating intent "${variables}"`, 'error');
            },
        },
    );
};

export const useUpdateIntentMutation = (onSuccess?: (intent: IPolicySet) => void) => {
    const api = useApi();
    return useMutation(
        'updateIntent',
        (update: { id: IPolicySet['id']; name: string; category: string }) =>
            api.put(`policy-set/${update.id}`, update) as Promise<IPolicySet>,
        { onSuccess },
    );
};

export const useIntentExists = () => {
    const intents = useIntentsQuery();

    return useCallback(
        (intentName: string) =>
            (intents.data ?? []).some(({ name }) => name.trim().toLowerCase() === intentName.trim().toLowerCase()),
        [intents.data],
    );
};

export const useDecisionLlmClustersQuery = (organizationId: number, policySetId: number) => {
    const api = useApi();
    return useQuery(
        [DECISION_LLM_CLUSTERS_QUERY_KEY, organizationId, policySetId],
        () =>
            api.get(`organization/${organizationId}/decision-llm-clusters`, {
                params: { policy_set_id: policySetId },
            }) as Promise<[string, string][]>,
    );
};
