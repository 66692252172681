import maxBy from 'lodash/maxBy';
import {
    createContext,
    forwardRef,
    PropsWithChildren,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState,
} from 'react';
import {
    applyArgUpdates,
    getVisibleArgs,
    IArgument,
    IArgumentUpdate,
    IDineshTicketOperations,
    isCategorical,
    isPolicyEvaluated,
    isPolicyEvaluationRunning,
    isTextArgument,
    ITicket,
    requiresInput,
    wereArgsChanged,
} from '@tymely/atoms';
import {
    AppMode,
    IWaitType,
    useAgentResponse,
    useAppMode,
    useCreateTicketCrumb,
    useEditArgumentsMutation,
    useEvaluatePolicy,
    useEventSubscription,
    useIsTicketLocked,
    useSelectedComment,
    useSelectedCommentDecisionQuery,
    useSetAlert,
    useTicket,
    useTicketUserWaited,
} from '@tymely/services';
import { useSetRecoilState } from 'recoil';
import { useKeys } from '@tymely/utils';
import { Key } from 'ts-key-enum';

import { isHighConfidenceArgument } from './utils';
import useArgumentsQuery from './useArgumentsQuery';

const getFindAccessoryArg = (updatedArg: IArgument) => (arg: IArgument) => {
    if (!isCategorical(arg)) {
        return false;
    }
    if ([arg.search_md_id, arg.regulator_md_id].includes(updatedArg.md_id)) {
        return true;
    }
    if (arg.lazy && arg.group_by === updatedArg.name) {
        return true;
    }
    return false;
};

type ArgumentsTabsContext = {
    argsLastUpdate?: Date;
    version?: string;
    approvedArgs?: Set<IArgument['md_id']>;
    onHighlightText?: (text: string) => void;
    onEvaluatePolicy: () => Promise<void>;
    textArgs: IArgument[];
    updates: IArgumentUpdate[];
    onUpdate: (newUpdates: IArgumentUpdate[]) => void;
};

const argumentsTabsContext = createContext<ArgumentsTabsContext | undefined>(undefined);

const _Provider = argumentsTabsContext.Provider;

export type ArgumentsTabsProviderProps = PropsWithChildren<
    Pick<ArgumentsTabsContext, 'version' | 'onHighlightText' | 'argsLastUpdate'>
>;

export type ArgumentsTabsProviderRef = {
    reset: () => void;
};

const useCommentSubscription = (version?: string) => {
    const setAlert = useSetAlert();
    const comment = useSelectedComment();
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const setPolicyEvaluated = useSetRecoilState(isPolicyEvaluated);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const setPolicyEvaluationRunning = useSetRecoilState(isPolicyEvaluationRunning);
    const agentResponseQuery = useAgentResponse();
    const decisionQuery = useSelectedCommentDecisionQuery(!version);
    const isTicketLockedQuery = useIsTicketLocked(comment?.ticket_id);

    useEventSubscription(
        { channel: `comment/${comment?.id}`, objectTypes: ['Decision', 'Error', 'TicketAlreadyAcquired'] },
        (event) => {
            isTicketLockedQuery.refetch();
            if (event.data && event.data.error) {
                setAlert(event.data.error as string, 'error');
                return;
            }
            return Promise.all([decisionQuery.refetch(), agentResponseQuery.refetch()]).finally(() => {
                setPolicyEvaluationRunning(false);
                setPolicyEvaluated(true);
            });
        },
        () => setPolicyEvaluationRunning(false),
        [comment?.id],
    );
};

const useTicketSubscription = (ticketId?: ITicket['id'], version?: string) => {
    const argsQuery = useArgumentsQuery({ version });
    const isTicketLockedQuery = useIsTicketLocked(ticketId);
    const ticket = useTicket();

    useEventSubscription(
        {
            channel: `ticket/origin/${ticket?.original_id_from_client.replace(/\W+/g, '')}`,
            objectTypes: ['TicketLifeCycleLock'],
        },
        () => isTicketLockedQuery.refetch(),
    );

    useEventSubscription({ channel: `ticket/${ticket.id}`, objectTypes: ['Argument'] }, () => {
        argsQuery.refetch();
    });
};

const ArgumentsTabsProvider = forwardRef<ArgumentsTabsProviderRef, ArgumentsTabsProviderProps>(
    ({ version, argsLastUpdate, onHighlightText, children }) => {
        const createTicketCrumb = useCreateTicketCrumb();
        const comment = useSelectedComment();
        const setAlert = useSetAlert();
        const { appMode } = useAppMode();
        const [updates, setUpdates] = useState<IArgumentUpdate[]>([]);
        const { waitFor } = useTicketUserWaited();
        const setPolicyEvaluated = useSetRecoilState(isPolicyEvaluated);

        const argsQuery = useArgumentsQuery({
            version,
            onSuccess: () => createTicketCrumb(IDineshTicketOperations.USER_GOT_UPDATED_ARGUMENTS),
            onError: () => setAlert('There was an error while fetching arguments', 'error'),
        });

        const editMutation = useEditArgumentsMutation({
            onSuccess() {
                setUpdates([]);
            },
            version,
        });

        const textArgs = useMemo(
            () => getVisibleArgs(applyArgUpdates(argsQuery.data.filter(isTextArgument), updates)),
            [argsQuery.data, updates],
        );

        const approvedArgs = useMemo(() => {
            return new Set(
                (textArgs ?? [])
                    .filter(
                        (arg) =>
                            !requiresInput(arg) &&
                            ((appMode !== AppMode.QA && isHighConfidenceArgument(arg)) ||
                                arg.is_edited ||
                                arg.approved_at),
                    )
                    .map((arg) => arg.md_id),
            );
        }, [textArgs]);

        const evaluatePolicyMutation = useEvaluatePolicy({
            onSuccess() {
                setUpdates([]);
            },
            onError: () => setAlert('There was an error while evaluating policy', 'error'),
        });

        const onEvaluatePolicy = useCallback(async () => {
            createTicketCrumb(IDineshTicketOperations.USER_CLICKED_OK);
            if (!version && updates.length) {
                await editMutation.mutateAsync(updates);
            } else if (comment) {
                await evaluatePolicyMutation.mutateAsync({ commentId: comment.id, runAsync: true });
            }
        }, [version, updates, comment]);

        useEffect(() => {
            if (
                appMode === AppMode.QA ||
                !updates.length ||
                editMutation.isLoading ||
                evaluatePolicyMutation.isLoading
            ) {
                return;
            }

            // Update locally if it's historical args.
            if (version) {
                argsQuery.updateArguments(applyArgUpdates(argsQuery.data, updates));
                setUpdates([]);
                return;
            }

            // Trigger early if system/accessory arg updated
            if (
                updates.find((update) => {
                    const updatedArg = argsQuery.data.find((arg) => arg.id === update.id);
                    if (!updatedArg || !wereArgsChanged(applyArgUpdates([updatedArg], updates), [updatedArg])) {
                        return false;
                    }
                    const sysArgUpdated = updatedArg.arg_type === 'SYSTEM_ARGUMENT';
                    const accessoryArgUpdated = argsQuery.data.find(getFindAccessoryArg(updatedArg));
                    return sysArgUpdated || accessoryArgUpdated;
                })
            ) {
                waitFor(IWaitType.POLICY_EVALUATION, editMutation.mutateAsync(updates));

                // Trigger if we filled all args of the same rank
            } else if (textArgs) {
                const highestFilledRank =
                    maxBy(
                        textArgs.filter(
                            (arg) =>
                                !isHighConfidenceArgument(arg) && approvedArgs.has(arg.md_id) && !requiresInput(arg),
                        ),
                        'rank',
                    )?.rank ?? -1;

                if (
                    highestFilledRank >= 0 &&
                    textArgs
                        .filter((arg) => arg.rank === highestFilledRank)
                        .every((arg) => approvedArgs.has(arg.md_id) && !requiresInput(arg))
                ) {
                    waitFor(
                        IWaitType.POLICY_EVALUATION,
                        wereArgsChanged(textArgs, argsQuery.data)
                            ? editMutation.mutateAsync(updates)
                            : evaluatePolicyMutation.mutateAsync({ commentId: comment?.id!, runAsync: true }),
                    );
                }
            }
        }, [textArgs, updates, approvedArgs, version, appMode]);

        const onUpdate = useCallback(
            (newUpdates: IArgumentUpdate[]) => {
                setUpdates((updates) =>
                    updates
                        .filter((update) => !newUpdates.find((newUpdate) => newUpdate.id === update.id))
                        .filter((update) => argsQuery.data.find((arg) => arg.id === update.id))
                        .concat(newUpdates),
                );
                setPolicyEvaluated(false);
            },
            [argsQuery.data],
        );

        useTicketSubscription(comment?.ticket_id, version);
        useKeys([Key.Control, Key.Alt, 'KeyO'], onEvaluatePolicy);

        useCommentSubscription(version);

        return (
            <_Provider
                value={{
                    version,
                    textArgs,
                    approvedArgs,
                    onHighlightText,
                    onEvaluatePolicy,
                    updates,
                    argsLastUpdate,
                    onUpdate,
                }}
            >
                {children}
            </_Provider>
        );
    },
);

ArgumentsTabsProvider.displayName = 'ArgumentsTabsProvider';

export const useArgumentsTabsContext = () => {
    const context = useContext(argumentsTabsContext);
    if (!context) {
        throw Error("'useArgumentsTabsContext' cannot be used outside of 'ArgumentsTabsProvider'");
    }
    return context;
};

export default ArgumentsTabsProvider;
