import { IComment, ITicket } from '@tymely/atoms';

import { useApi } from './axios';

export const useSearchTickets = () => {
    const api = useApi();
    return (params: { query: string; org: number; maxResults?: number }, signal?: AbortSignal): Promise<ITicket[]> =>
        api.get('tickets/search', {
            params: {
                search_term: params.query,
                org_id: params.org,
                max_results: params.maxResults,
            },
            signal,
        }) as Promise<ITicket[]>;
};

export const useFetchCommentsSince = () => {
    const api = useApi();
    return (ticketId: number, createdSince?: string, updatedSince?: string) =>
        api.get(`ticket/${ticketId}/comments`, {
            params: {
                created_since: createdSince,
                updated_since: updatedSince || undefined,
            },
        }) as Promise<IComment[]>;
};
