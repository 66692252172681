import React from 'react';
import { groupBy } from 'lodash';
import { useMutation, UseMutationOptions, useQuery, useQueryClient } from 'react-query';
import { useApi } from '@tymely/api';
import { IPrompt, IPromptCreate, IOrganization, LlmModelMetadata } from '@tymely/atoms';
import { AxiosError } from 'axios';

export const useLlmModelMetadatasQuery = () => {
    const api = useApi();
    return useQuery(['llm-model-metadatas'], () => api.get('/llm-model-metadatas/list') as Promise<LlmModelMetadata[]>);
};

export const QUERY_PROMTS = 'prompts';

export const usePromptsQuery = (organizationId?: IOrganization['id']) => {
    const api = useApi();
    const promptsQuery = useQuery([QUERY_PROMTS, organizationId], () =>
        api.get<IPrompt[]>('/prompt-template/list/by-org', {
            params: {
                limit: 2000,
                include_deleted: true,
                organization_id: organizationId,
            },
        }),
    );

    const data = React.useMemo(() => {
        const groups = groupBy(promptsQuery.data, 'name');
        return Object.keys(groups)
            .map((name) => {
                const versions = groups[name].sort((a, b) => {
                    if (a.deleted_at && !b.deleted_at) {
                        return 1;
                    }
                    if (!a.deleted_at && b.deleted_at) {
                        return -1;
                    }
                    return new Date(b.created_date).valueOf() - new Date(a.created_date).valueOf();
                });
                const current = versions.find((v) => !v.deleted_at);
                return { name, current, versions };
            })
            .sort((a, b) => {
                const promptA = a.current;
                const promptB = b.current;
                const dateA = new Date(promptA?.created_date ?? 0);
                const dateB = new Date(promptB?.created_date ?? 0);
                return dateB.getTime() - dateA.getTime();
            });
    }, [promptsQuery.data]);

    return { ...promptsQuery, data };
};

type UseCreatePromptMutationArgs = UseMutationOptions<unknown, unknown, IPromptCreate>;

export const useCreatePromptMutation = (options?: UseCreatePromptMutationArgs) => {
    const api = useApi();
    const client = useQueryClient();
    return useMutation<IPrompt, AxiosError, IPromptCreate>(
        (prompt: IPromptCreate) => api.post('prompt-template', prompt) as Promise<IPrompt>,
        {
            ...options,
            onSuccess: (data: IPrompt) => {
                client.setQueryData([QUERY_PROMTS, data.organization_id], (prompts?: IPrompt[]) => {
                    if (!prompts) {
                        return [data];
                    }
                    if (data.deleted_at) {
                        return prompts.concat(data);
                    }
                    return prompts
                        .map((prompt) => {
                            if (prompt.name === data.name && !prompt.deleted_at) {
                                return { ...prompt, deleted_at: new Date().toISOString() };
                            }
                            return prompt;
                        })
                        .concat(data);
                });
            },
        },
    );
};

type UseUpdatePromptMutationArgs = UseMutationOptions<unknown, unknown, IPrompt>;

export const useUpdatePromptMutation = (options?: UseUpdatePromptMutationArgs) => {
    const api = useApi();
    const client = useQueryClient();
    return useMutation<IPrompt, AxiosError, IPrompt>(
        async (prompt: IPrompt) => {
            const { id, created_date, ...rest } = prompt;
            await api.put(`prompt-template/${id}`, rest);
            return prompt;
        },
        {
            ...options,
            onSuccess: (updatedPrompt) => {
                client.setQueryData([QUERY_PROMTS, updatedPrompt.organization_id], (prompts?: IPrompt[]) => {
                    if (!prompts) {
                        return [];
                    }
                    return prompts.map((prompt) => {
                        if (prompt.id !== updatedPrompt.id) {
                            return prompt;
                        }
                        return updatedPrompt;
                    });
                });
            },
        },
    );
};

export const useSetLiveMutation = () => {
    const api = useApi();
    const client = useQueryClient();
    return useMutation(
        (prompt: IPrompt) =>
            api
                .put<IPrompt>(`prompt-template/${prompt.id}/set-live`)
                .then(() => ({ ...prompt, deleted_at: undefined })),
        {
            onSuccess: (livePrompt) => {
                client.setQueryData([QUERY_PROMTS, livePrompt.organization_id], (prompts?: IPrompt[]) => {
                    if (!prompts) {
                        return [];
                    }
                    return prompts.map((prompt) => {
                        if (prompt.name !== livePrompt.name) {
                            return prompt;
                        }
                        const deleted_at = prompt.id !== livePrompt.id ? new Date().toISOString() : undefined;
                        return { ...prompt, deleted_at };
                    });
                });
            },
        },
    );
};
