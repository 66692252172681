import sortBy from 'lodash/sortBy';
import { JSONSchema7 } from 'json-schema';
import { useState, useEffect, useMemo, useCallback } from 'react';
import {
    Box,
    TextField,
    Button,
    Typography,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Link,
    useTheme,
    CircularProgress,
    Tooltip,
    Grid,
    Card,
    List,
    MenuItem,
} from '@mui/material';
import { useForm } from 'react-hook-form';
import { TypeOf, z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import EditIcon from '@mui/icons-material/Edit';
import CheckIcon from '@mui/icons-material/Check';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import {
    IArgumentMetadata,
    LlmExperimentType,
    ILlmExperiment,
    ILlmReportRecord,
    ILlmSummaryReport,
    ITicket,
    IArgument,
    IArgumentUpdate,
    LlmExperimentDataSetType,
} from '@tymely/atoms';
import {
    useCreateLlmExperiment,
    useEditArgumentMetadataMutation,
    useFetchCommentArguments,
    useRunLlmExperiment,
    useSetTicket,
} from '@tymely/services';
import { BaseModal, CellTypography, CopyToClipboardButton, ThickLinearProgress } from '@tymely/components';
import { useApi, useFetchLlmExperimentReport, useFetchLlmExperimentSummaryReport, useSignS3Url } from '@tymely/api';
import { useLlmExperimentsSearch } from '@tymely/services';
import { MaterialReactTable, MRT_Row, useMaterialReactTable } from 'material-react-table';
import LlmModelDropdown from '@tymely/pages/PromptEditor/components/LlmModelDropdown';

import { TicketModal } from '../Ticket/TicketModal';
import { ArgEditor, getDisplayRegulatorArg, getGroupArg, getSearchArg } from '../Ticket/Arguments/Sections';

function buildStats(records: ILlmReportRecord[], summary: ILlmSummaryReport, _: IArgumentMetadata) {
    const answerFreq: { [key: string]: number } = {};
    const confidenceFreq: { [key: number]: number } = {};
    const total = records.length;

    for (const record of records) {
        const answer = record.predicted_value as string;
        const confidence = record.predicted_confidence as number;
        answerFreq[answer] = ((answerFreq[answer] as number) || 0) + 1;
        confidenceFreq[confidence] = ((confidenceFreq[confidence] as number) || 0) + 1;
    }

    for (const answer in answerFreq) {
        answerFreq[answer] = answerFreq[answer] / total;
    }
    for (const confidence in confidenceFreq) {
        confidenceFreq[confidence] = confidenceFreq[confidence] / total;
    }

    const stats = {
        answer: answerFreq,
        confidence: confidenceFreq,
        precision: summary.overall_precision,
        recall: summary.overall_precision,
        records: sortBy(records, (record) => -record.predicted_confidence).map((record, at) => ({ ...record, id: at })),
    };

    return stats;
}

export function PromptExperimentModal(props: {
    argMetadata: IArgumentMetadata;
    llmExperimentSchema: JSONSchema7;
    onSubmit: (description: string) => Promise<unknown>;
    onClose: () => void;
}) {
    const setTicket = useSetTicket();
    const [saving, setSaving] = useState(false);
    const [stats, setStats] = useState<any>();
    const [loadingTicketId, setLoadingTicketId] = useState<number>();
    const [openTicket, setOpenTicket] = useState<ITicket>();
    const [selectedComment, setSelectedComment] = useState<ILlmReportRecord['comment_id']>();
    const [argMetadata, setArgMetadata] = useState<IArgumentMetadata>(props.argMetadata);

    const formSchema = useMemo(
        () =>
            z
                .object({
                    description: z.string().min(1, { message: 'Description is required' }),
                    set_size: z.coerce.number().min(1, { message: 'Test set size' }).optional().or(z.literal('')),
                    model: z.number().optional(),
                    temperature: z.coerce.number().min(0).max(2).optional(),
                    top_p: z.coerce.number().min(0).max(1).optional(),
                    comment_ids: z
                        .string()
                        .transform((value) => value.split(/[\s,]/).filter(Boolean).map(Number))
                        .pipe(z.number().array()),
                    '': z.any(),
                })
                .refine(
                    (data) => data.comment_ids?.length || data.set_size,
                    'Either set size or comment IDs must be provided.',
                ),
        [],
    );

    const signS3Url = useSignS3Url();
    const openS3File = async (url: string) => {
        const signedUrl = await signS3Url(url);
        window.open(signedUrl, '_blank');
    };

    type FormInput = TypeOf<typeof formSchema>;

    const {
        register,
        trigger,
        formState: { errors, isValid },
        getValues,
        setValue,
        resetField,
        handleSubmit,
    } = useForm<FormInput>({
        resolver: zodResolver(formSchema),
        defaultValues: {
            ...argMetadata,
            description: (argMetadata.params?.prompt_override as string) || argMetadata.description || '',
            set_size: 10,
        },
    });

    const { data: history, isLoading: isLoadingHistory } = useLlmExperimentsSearch(
        LlmExperimentType.ARGUMENT_OFFLINE,
        argMetadata.name,
    );
    useEffect(() => {
        setValue('description', (argMetadata.params?.prompt_override as string) || argMetadata.description || '');
        if (llmExperiment?.total_count) {
            setValue('set_size', llmExperiment?.total_count);
        } else {
            resetField('set_size');
        }
    }, [argMetadata]);

    const fetchLlmExperimentReport = useFetchLlmExperimentReport();
    const fetchLlmExperimentSummaryReport = useFetchLlmExperimentSummaryReport();
    const editArgMetadataMutation = useEditArgumentMetadataMutation();
    const createLlmExperiment = useCreateLlmExperiment();
    const [llmExperiment, setLlmExperiment] = useState<ILlmExperiment>();
    const { mutateAsync: runLlmExperiment } = useRunLlmExperiment();

    const onRunTestClick = handleSubmit(async (values: FormInput) => {
        trigger();
        if (!isValid) return;

        if (values.comment_ids?.length) {
            setValue('set_size', values.comment_ids?.length);
        }

        setLlmExperiment(undefined);
        setStats(undefined);
        setCreateReportCalled(false);

        setSaving(true);
        try {
            await editArgMetadataMutation.mutateAsync({
                ...props.argMetadata,
                params: {
                    ...props.argMetadata.params,
                    prompt_override: getValues('description'),
                },
            });
            const experiment = await createLlmExperiment.mutateAsync({
                experiment_type: LlmExperimentType.ARGUMENT_OFFLINE,
                total_count: values.comment_ids?.length || values.set_size || 0,
                request_override: {
                    arg_md_id: props.argMetadata.id,
                    llm_model_metadata_id: values.model,
                    llm_params: {
                        temperature: values.temperature || undefined,
                        top_p: values.top_p || undefined,
                    },
                },
                additional_data: {
                    experiment_obj_id: props.argMetadata.id!,
                    optimization_target_name: props.argMetadata.name!,
                },
            });
            setLlmExperiment(experiment);

            runLlmExperiment({
                experimentId: experiment.id,
                dataset: values.comment_ids?.length
                    ? {
                          type: LlmExperimentDataSetType.COMMENT,
                          ids: values.comment_ids,
                          name: new Date().toISOString(),
                      }
                    : undefined,
            }).catch((error) => {
                setError(error.message);
                api.put(`llm-experiment/${experiment.id}`, {
                    ...experiment,
                    additional_data: {
                        ...experiment.additional_data,
                        error: error.message,
                    },
                });
            });
        } finally {
            setSaving(false);
        }
    });

    useEffect(() => {
        if (llmExperiment) {
            setError(llmExperiment.additional_data?.error || null);
        }
    }, [llmExperiment?.id]);

    const theme = useTheme();
    const [createReportCalled, setCreateReportCalled] = useState(false);
    const [error, setError] = useState<string | null>(null);
    useEffect(() => {
        if (!llmExperiment || llmExperiment.additional_data?.error || error) return;
        let interval: ReturnType<typeof setTimeout> | null = setInterval(poll, 1000);
        async function poll() {
            if (!llmExperiment?.id) return;
            const experimentUpdated = (await api.get(`llm-experiment/${llmExperiment.id}`)) as ILlmExperiment;
            if (!interval) return;

            setLlmExperiment(experimentUpdated);

            if (experimentUpdated.processed_count >= experimentUpdated.total_count) {
                if (experimentUpdated.report_link && experimentUpdated.report_analysis_link) {
                    clearInterval(interval);
                    setIsLoading(true);
                    try {
                        const [report, summary] = await Promise.all([
                            signS3Url(experimentUpdated.report_link).then(fetchLlmExperimentReport),
                            signS3Url(experimentUpdated.report_analysis_link).then(fetchLlmExperimentSummaryReport),
                        ]);
                        await Promise.all(
                            report.map(async (record) => {
                                try {
                                    record.comment = await api.get(`comment/${record.comment_id}`);
                                } catch (_) {
                                    // comment is not available
                                }
                            }),
                        );
                        if (!interval) return;
                        interval = null;
                        setStats(buildStats(report, summary, props.argMetadata as IArgumentMetadata));
                    } finally {
                        setIsLoading(false);
                    }
                } else if (!createReportCalled) {
                    api.get(`llm-experiment/${experimentUpdated.id}/create-report`).catch((error) =>
                        setError(error.message),
                    );
                    setCreateReportCalled(true);
                }
            }
        }
        poll();
        return () => {
            interval && clearInterval(interval);
            interval = null;
        };
    }, [llmExperiment?.id, createReportCalled, error]);
    const [isLoading, setIsLoading] = useState(false);

    const progress = useMemo(() => {
        if (!llmExperiment) return null;
        return Math.min(100, Math.round((llmExperiment.processed_count / llmExperiment.total_count) * 100));
    }, [llmExperiment]);

    const onSubmitHandler = () => {
        props.onSubmit(getValues('description'));
    };

    const [editArgumentName, setEditArgumentName] = useState<IArgumentMetadata['name']>();
    const [editArgumentTicketId, setEditArgumentTicketId] = useState<ITicket['id']>();
    const [args, setArgs] = useState<IArgument[]>();
    const [arg, setArg] = useState<IArgument>();
    const groupArgument = useMemo(() => args && arg && getGroupArg(arg, args), [arg]);
    const regulatoryArgument = useMemo(
        () => args && groupArgument && arg && getDisplayRegulatorArg(groupArgument ?? arg, args),
        [arg, groupArgument],
    );
    const searchArgument = useMemo(
        () => args && arg && getSearchArg(groupArgument ?? arg, args),
        [args, arg, groupArgument],
    );
    const [argUpdates, setArgUpdates] = useState<IArgumentUpdate[]>();
    const [updatingArgumentForTicketId, setUpdatingArgumentForTicketId] = useState<ITicket['id']>();
    const fetchCommentArguments = useFetchCommentArguments();
    const api = useApi();

    const updateArg = useCallback(
        async (row: MRT_Row<ILlmReportRecord>, updates?: IArgumentUpdate[]) => {
            if (updates) {
                setUpdatingArgumentForTicketId(row.original.ticket_id);
                try {
                    await api.put(
                        'arguments/edit',
                        {
                            update_data: updates,
                            update_argument_tree: true,
                        },
                        {
                            params: {
                                comment_id: row.original.comment_id,
                                async: true,
                            },
                        },
                    );
                    const args = await fetchCommentArguments(row.original.comment_id);
                    const arg = args.find((arg) => arg.name === row.original.arg_name);
                    setArg(arg);
                    setArgs(args);
                    setArgUpdates(undefined);
                    setStats({
                        ...stats,
                        records: stats.records.map((record: ILlmReportRecord) =>
                            record.llm_trail_id === row.original.llm_trail_id
                                ? {
                                      ...record,
                                      arg_value: arg!.value,
                                  }
                                : record,
                        ),
                    });
                } finally {
                    setUpdatingArgumentForTicketId(undefined);
                }
            }
            setEditArgumentName(undefined);
            setEditArgumentTicketId(undefined);
            setArgs(undefined);
            setArg(undefined);
        },
        [argUpdates, stats, setArg, setArgs, setArgUpdates, setEditArgumentName, setEditArgumentTicketId],
    );

    const table = useMaterialReactTable<ILlmReportRecord>({
        enablePagination: false,
        enableBottomToolbar: false,
        enableStickyHeader: true,
        columns: [
            {
                header: 'Ticket',
                accessorKey: 'ticket_id',
                Cell: ({ cell, row }) => {
                    const ticket_id = cell.getValue();
                    return (
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                            }}
                        >
                            <Button
                                color="warning"
                                disabled={loadingTicketId === ticket_id}
                                onClick={async () => {
                                    setLoadingTicketId(ticket_id);
                                    try {
                                        const ticket = (await api.get(`ticket/${ticket_id}`)) as ITicket;
                                        setTicket(ticket);
                                        setOpenTicket(ticket);
                                        setSelectedComment(row.original.comment_id);
                                    } finally {
                                        setLoadingTicketId(undefined);
                                    }
                                }}
                                sx={{
                                    display: 'flex',
                                    gap: 1,
                                    alignItems: 'center',
                                }}
                            >
                                <span>{ticket_id}</span>
                                {loadingTicketId === ticket_id && (
                                    <CircularProgress size={15} color="info" sx={{ mt: '-1px' }} />
                                )}
                            </Button>

                            <Link
                                href={`/ticket/${ticket_id}`}
                                target="_blank"
                                rel="noreferrer"
                                sx={{
                                    mb: '-3px',
                                    color: theme.palette.warning.main,
                                }}
                            >
                                <OpenInNewIcon />
                            </Link>
                        </Box>
                    );
                },
            },
            {
                header: 'Predicted value',
                accessorKey: 'predicted_value',
            },
            {
                header: 'Correct?',
                accessorKey: 'predicted_correctly',
                Cell: ({ row }) => <CellTypography>{row.original.predicted_correctly ? 'Yes' : 'No'}</CellTypography>,
            },
            {
                header: 'Value in DB',
                accessorKey: 'arg_value',
                Cell: ({ cell, row }) => {
                    const value = cell.getValue();
                    return (
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                            }}
                        >
                            {arg &&
                            row.original.arg_name === editArgumentName &&
                            editArgumentTicketId === row.original.ticket_id ? (
                                <Box sx={{ flex: 1, display: 'flex', gap: 1 }}>
                                    <Tooltip title="Unspecified" enterDelay={0.5}>
                                        <Button
                                            aria-label="unspecified"
                                            color="black"
                                            onClick={() => {
                                                updateArg(row, [
                                                    {
                                                        id: arg.id,
                                                        value: null,
                                                        special_value: 'unspecified',
                                                    },
                                                ]);
                                            }}
                                            disabled={
                                                row.original.ticket_id === updatingArgumentForTicketId ||
                                                arg.special_value === 'unspecified' ||
                                                !arg.unspecifiable
                                            }
                                            sx={{ minWidth: 0 }}
                                        >
                                            {arg.special_value === 'unspecified' ? (
                                                <RadioButtonCheckedIcon fontSize="small" />
                                            ) : (
                                                <RadioButtonUncheckedIcon fontSize="small" />
                                            )}
                                        </Button>
                                    </Tooltip>

                                    <ArgEditor
                                        argument={arg}
                                        loading={row.original.ticket_id === updatingArgumentForTicketId}
                                        groupArgument={groupArgument}
                                        displayRegulatorArgument={regulatoryArgument}
                                        searchArgument={searchArgument}
                                        onChange={async (args) => {
                                            updateArg(
                                                row,
                                                args.map((arg) => ({
                                                    id: arg.id,
                                                    value: arg.value,
                                                    special_value: arg.special_value || undefined,
                                                })),
                                            );
                                            return args;
                                        }}
                                    />

                                    <Button
                                        color="black"
                                        sx={{
                                            minWidth: 0,
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                        }}
                                        disabled={row.original.ticket_id === updatingArgumentForTicketId}
                                        onClick={() => updateArg(row, argUpdates)}
                                    >
                                        <CheckIcon />
                                    </Button>
                                </Box>
                            ) : (
                                <>
                                    {value === null ? <i>Unspecified</i> : value}
                                    <Button
                                        color="black"
                                        disabled={
                                            editArgumentName === row.original.arg_name &&
                                            editArgumentTicketId === row.original.ticket_id &&
                                            !args
                                        }
                                        onClick={async () => {
                                            setArg(undefined);
                                            setArgs(undefined);
                                            setEditArgumentName(row.original.arg_name);
                                            setEditArgumentTicketId(row.original.ticket_id);
                                            const args = await fetchCommentArguments(row.original.comment_id);
                                            setArg(args.find((arg) => arg.name === row.original.arg_name));
                                            setArgs(args);
                                            setArgUpdates(undefined);
                                        }}
                                        sx={{ p: 1, minWidth: 0 }}
                                    >
                                        <EditIcon />
                                    </Button>
                                </>
                            )}
                        </Box>
                    );
                },
            },
            {
                header: 'Confidence',
                accessorKey: 'predicted_confidence',
            },
            {
                header: 'Comment',
                accessorKey: 'comment_id',
                Cell: ({ row }) => {
                    return row.original.comment ? (
                        <CellTypography
                            dangerouslySetInnerHTML={{
                                __html: row.original.comment.body_html || '',
                            }}
                        />
                    ) : (
                        <b>{row.original.comment_id}</b>
                    );
                },
            },
        ],
        data: stats ? stats.records : [],
        renderEmptyRowsFallback: stats?.records ? undefined : () => <CircularProgress />,
        muiTablePaperProps: {
            sx: {
                height: '70vh',
                display: 'flex',
                flexDirection: 'column',
                mb: 2,
            },
        },
    });

    return (
        <BaseModal
            open={!!props.argMetadata}
            onClose={props.onClose}
            title={`${props.argMetadata.name} argument experiment`}
            loading={saving}
            fullHeight
            fullWidth
            maxWidth={false}
            okLabel="Save to argument"
            onOk={onSubmitHandler}
            closable={!saving}
            footer={
                <Box sx={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
                    {llmExperiment && (
                        <Typography sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                            Experiment #{llmExperiment?.id}
                            <CopyToClipboardButton size="small" text={String(llmExperiment.id)} />
                        </Typography>
                    )}
                </Box>
            }
        >
            <Grid
                container
                width="100%"
                height="100%"
                maxWidth="xxl"
                columnSpacing={{ xs: 0, md: 2 }}
                rowSpacing={{ xs: 2, md: 0 }}
            >
                <Grid
                    item
                    sx={{
                        height: 0.35,
                        width: '330px',
                        [`${theme.breakpoints.up('md')}`]: { height: 1 },
                    }}
                >
                    <Card
                        sx={{
                            height: 1,
                            p: 2,
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'space-between',
                        }}
                    >
                        <List sx={{ height: 1, overflow: 'auto' }}>
                            <MenuItem
                                selected={!llmExperiment}
                                disabled={isLoading}
                                onClick={() => {
                                    setLlmExperiment(undefined);
                                    setArgMetadata(props.argMetadata);
                                }}
                            >
                                Create new experiment
                            </MenuItem>
                            {isLoadingHistory && (
                                <MenuItem>
                                    <CircularProgress />
                                </MenuItem>
                            )}
                            {history &&
                                history.map((_llmExperiment) => (
                                    <MenuItem
                                        key={_llmExperiment.id}
                                        selected={_llmExperiment.id === llmExperiment?.id}
                                        disabled={isLoading}
                                        sx={{ whiteSpace: 'wrap', mb: 1, fontSize: '0.95em' }}
                                        onClick={async () => {
                                            setError(null);
                                            setCreateReportCalled(false);
                                            setStats(undefined);

                                            setLlmExperiment(_llmExperiment);
                                            const argMdId = _llmExperiment.additional_data.experiment_obj_id as number;
                                            const argMd = (await api.get(
                                                `argument-metadata/${argMdId}`,
                                            )) as IArgumentMetadata;
                                            setArgMetadata(argMd);
                                        }}
                                    >
                                        {_llmExperiment.created_date}
                                    </MenuItem>
                                ))}
                        </List>
                    </Card>
                </Grid>
                <Grid
                    item
                    display="flex"
                    flexDirection="column"
                    sx={{
                        height: 1,
                        overflow: 'auto',
                        flex: 1,
                    }}
                >
                    <Card
                        sx={{
                            height: 1,
                            marginBottom: 2,
                            border: '2px solid transparent',
                            overflowY: 'auto',
                            position: 'relative',
                        }}
                    >
                        <form noValidate style={{ textAlign: 'center' }}>
                            <TextField
                                required
                                fullWidth
                                margin="dense"
                                label="Description"
                                type="text"
                                multiline
                                rows={8}
                                variant="outlined"
                                error={!!errors.description}
                                helperText={errors.description?.message || ''}
                                sx={{ mb: 1 }}
                                {...register('description')}
                            />
                            <Box sx={{ display: 'flex', justifyContent: 'center', gap: 2 }}>
                                <TextField
                                    required={!getValues('comment_ids')}
                                    autoFocus
                                    size="small"
                                    disabled={saving}
                                    margin="dense"
                                    label="Test set size"
                                    type="number"
                                    variant="outlined"
                                    error={!!errors.set_size}
                                    helperText={errors.set_size?.message || ''}
                                    sx={{ width: theme.spacing(20) }}
                                    {...register('set_size')}
                                />
                                <LlmModelDropdown inputProps={register('model')} onChange={() => trigger('model')} />
                                <TextField
                                    size="small"
                                    disabled={saving}
                                    margin="dense"
                                    label="Temperature"
                                    type="number"
                                    variant="outlined"
                                    error={!!errors.temperature}
                                    helperText={errors.temperature?.message || ''}
                                    sx={{ width: theme.spacing(20) }}
                                    {...register('temperature')}
                                />
                                <TextField
                                    size="small"
                                    disabled={saving}
                                    margin="dense"
                                    label="Top P"
                                    type="number"
                                    variant="outlined"
                                    error={!!errors.top_p}
                                    helperText={errors.top_p?.message || ''}
                                    sx={{ width: theme.spacing(20) }}
                                    {...register('top_p')}
                                />
                            </Box>
                            <TextField
                                fullWidth
                                margin="dense"
                                label="Comment IDs (optional)"
                                type="text"
                                multiline
                                rows={3}
                                variant="outlined"
                                error={!!errors.comment_ids}
                                helperText={errors.comment_ids?.message || ''}
                                sx={{ mb: 1 }}
                                {...register('comment_ids')}
                            />
                            {errors[''] && <Typography color="error">{String(errors[''].message)}</Typography>}
                        </form>

                        {error ? (
                            <Typography color="error">{error}</Typography>
                        ) : (
                            <Box sx={{ display: 'flex', justifyContent: 'center', gap: 2, mt: 2 }}>
                                {progress !== null && progress !== 100 ? (
                                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                        <ThickLinearProgress
                                            variant="determinate"
                                            color="secondary"
                                            sx={{ width: theme.spacing(30) }}
                                            value={progress}
                                        />
                                        {llmExperiment && (
                                            <Typography>
                                                {llmExperiment?.processed_count} / {llmExperiment?.total_count}
                                            </Typography>
                                        )}
                                    </Box>
                                ) : (
                                    <Button
                                        onClick={onRunTestClick}
                                        size="large"
                                        color="secondary"
                                        variant="contained"
                                        disabled={saving || isLoading}
                                    >
                                        {llmExperiment ? 'Re-run' : 'Run'}
                                    </Button>
                                )}
                            </Box>
                        )}

                        {llmExperiment?.report_link ? (
                            <Box
                                sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: 2, mt: 2 }}
                            >
                                <Typography color="gray">Experiment #{llmExperiment.id}</Typography>{' '}
                                <DoneAllIcon color="secondary" />
                                <Button
                                    variant="outlined"
                                    onClick={() => openS3File(llmExperiment.report_link!)}
                                    color="secondary"
                                >
                                    Report
                                </Button>
                                <Button
                                    variant="outlined"
                                    onClick={() => openS3File(llmExperiment.report_analysis_link!)}
                                    color="secondary"
                                >
                                    Report Analysis
                                </Button>
                            </Box>
                        ) : createReportCalled && !error ? (
                            <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                                <CircularProgress />
                            </Box>
                        ) : null}

                        {stats && (
                            <>
                                <Typography variant="h6" sx={{ mt: 3, mb: 1 }}>
                                    Summary
                                </Typography>
                                <TableContainer>
                                    <Table size="small" sx={{ borderCollapse: 'collapse' }}>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell variant="head">Value distribution</TableCell>
                                                <TableCell variant="head">Confidence ratio distribution</TableCell>
                                                <TableCell variant="head">Precision</TableCell>
                                                <TableCell variant="head">Recall</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            <TableRow>
                                                <TableCell>
                                                    <ul style={{ paddingLeft: theme.spacing(2) }}>
                                                        {Object.keys(stats.answer).map((answer, at) => (
                                                            <li key={at}>
                                                                {answer}: {Math.round(stats.answer[answer] * 100)}%
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </TableCell>
                                                <TableCell sx={{ p: 0, verticalAlign: 'top' }}>
                                                    <Table size="small">
                                                        <TableHead>
                                                            <TableRow>
                                                                <TableCell variant="head">Confidence</TableCell>
                                                                <TableCell variant="head">Ratio</TableCell>
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            {sortBy(Object.keys(stats.confidence), (val) => -val).map(
                                                                (confidence, at) => (
                                                                    <TableRow key={at}>
                                                                        <TableCell>{confidence}</TableCell>
                                                                        <TableCell>
                                                                            {stats.confidence[Number(confidence)]}
                                                                        </TableCell>
                                                                    </TableRow>
                                                                ),
                                                            )}
                                                        </TableBody>
                                                    </Table>
                                                </TableCell>
                                                <TableCell>{stats.precision}</TableCell>
                                                <TableCell>{stats.recall}</TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>

                                <Typography variant="h6" sx={{ mt: 3, mb: 1 }}>
                                    Data
                                </Typography>

                                <MaterialReactTable table={table} />

                                {openTicket && selectedComment && (
                                    <TicketModal ticket={openTicket} selectedCommentId={selectedComment} />
                                )}
                            </>
                        )}
                    </Card>
                </Grid>
            </Grid>
        </BaseModal>
    );
}
